html, body {
  height: 100%;
  min-height: 100%;

  font-family: 'Roboto';
}

//main {
//  position: absolute;
//  top: 0;
//  left: 0;
//  right: 0;
//  bottom: 0;
//}

a,
input,
button {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-focus-ring-color: rgba(0, 0, 0, 0);
}

.page {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //min-height: 100%;
  min-height: 100vh;
  text-align: center;
  padding: 20px;
  z-index: 1;
}

.landscape {
  position: absolute;
  display: none;

  height: 100%;

  background: $grey;

  overflow: hidden;
  z-index: 2;

  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media all and (orientation: landscape) {
  .landscape {
    display: block;
  }
  body {
    overflow: hidden;
  }
}

.container {
  position: relative;
  //min-height: 100%;
  min-height: calc(100vh - 40px);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: $white;
  border: 1px solid #C3C3C3;

  padding: 40px 27px;
}

.banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  display: none;

  background: $darkGreen;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: $white;

  padding: 12px 60px;

  z-index: 1;

  .icon {
    position: absolute;
    right: 20px;
    top: 10px;

    font-size: 28px;
  }
}

.logo {
  width: 130px;
  height: 130px;
  margin: auto;
}

.title {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 38px;

  text-align: center;

  color: $darkGreen;

  margin-top: 18px;
}

.description {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-align: center;

  color: $black;

  opacity: 0.75;

  margin-top: 20px;
}

.tips {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: $black;

  opacity: 0.75;

  margin-top: 20px;
}

.btns {
  display: block;

  margin-top: 20px;
}

.btn {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  color: $white;
  background: $darkGreen;

  border: 1px solid rgba(5, 62, 80, 0.1);
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(6px);

  padding: 14px 40px;
  margin: auto;

  & + .btn {
    margin-top: 20px;
  }

  .icon {
    margin-right: 16px;
  }

  .icon.icon-ios-white,
  .icon.icon-ios-grey {
    font-size: 28px;
  }

  .icon.icon-android-white,
  .icon.icon-android-grey {
    font-size: 18px;
  }

  .text {
    min-width: 108px;
  }
}

button[disabled] {
  color: #C6C6C6;
  background: rgba(153, 153, 153, 0.1);

  border: 1px solid rgba(153, 153, 153, 0.1);
}
