$black: #000000;
$darkGreen: #053E50;
$grey: #E7E7E7;
$white: #FFFFFF;

// Base
@import 'base/reset.scss';
@import 'base/mixins.scss';
@import 'base/utilities.scss';
@import 'base/keyframes.scss';
// Layout
@import 'layout.scss';
// Icomoon
@import 'font.scss';
