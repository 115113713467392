@import "variables";

/* eb-garamond-500 - latin */
@font-face {
  font-family: 'EB Garamond';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/eb-garamond-v26-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/eb-garamond-v26-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/eb-garamond-v26-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/eb-garamond-v26-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/eb-garamond-v26-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/eb-garamond-v26-latin-500.svg#EBGaramond') format('svg'); /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  //src: url('../assets/fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  //url('../assets/fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* manrope-500 - latin */
@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/manrope-v13-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../assets/fonts/manrope-v13-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../assets/fonts/manrope-v13-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../assets/fonts/manrope-v13-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../assets/fonts/manrope-v13-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../assets/fonts/manrope-v13-latin-500.svg#Manrope') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uv494l');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?uv494l#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?uv494l') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?uv494l') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?uv494l##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-android-grey {
  &:before {
    content: $icon-android-grey;
    color: #c6c6c6;
  }
}
.icon-ios-white {
  &:before {
    content: $icon-ios-white;
    color: #fff;
  }
}
.icon-android-white {
  &:before {
    content: $icon-android-white;
    color: #fff;
  }
}
.icon-ios-grey {
  &:before {
    content: $icon-ios-grey;
    color: #c6c6c6;
  }
}
.icon-arrow {
  &:before {
    content: $icon-arrow;
    color: #fff;
  }
}

