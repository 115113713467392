@keyframes pulse {
  from {
		transform: scale(1);
  }

  70% {
		transform: scale(1.12);
  }

  to {
		transform: scale(1);
  }
}

@keyframes shake {
  10%, 90% {
    transform: rotate(-2deg);
  }
  
  20%, 80% {
    transform: rotate(2deg);
  }

  30%, 50%, 70% {
    transform: rotate(-2deg);
  }

  40%, 60% {
    transform: rotate(2deg);
  }
}

@keyframes select {
  10% {
    transform: scale(.95);
  }
  
	60% {
		transform: scale(1.05);
	}
}

@keyframes slide {
	from {
		transform: translateY(0);
	}
	to {
		transform: translateY(calc(-100% + 100vh));
	}
}

@keyframes fadedown {
  from {
		transform: translate(-50%, -90%);
		opacity: 0;
	}
	30% {
		opacity: 1;
		transform: translate(-50%, 5%);
	}
	to {
		transform: translate(-50%, 5%);
		opacity: 1;
	}
}