.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.margin-center {
	margin: 0px auto;
}

.swiper-container {
	width: 100% !important;
	height: 100% !important;
	overflow: hidden;

	.swiper-slide {
		width: 100% !important;
	}
}

.disable {
	transition: opacity .2s;
	opacity: .2;
	// pointer-events: none;
}

.disable-noclick {
	transition: opacity .2s;
	opacity: .2;
	pointer-events: none;
}

.do-shake { 
  animation: select .8s;
}

